import React from 'react'
import { Routes, Route } from "react-router-dom"
import Home from '../pages/home'
import Expenses from '../pages/expenses'
import History from '../pages/history'
import Evolution from '../pages/evolution'
import TransactionManager from '../pages/transactionManager'

export default function CustomRouter(){

    return(
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/expenses/transaction/manage/:id" element={<TransactionManager />} />
            <Route path="/expenses/transaction/manage" element={<TransactionManager />} />
            <Route path="/expenses" element={<Expenses />} />
            <Route path="/history" element={<History />} />
            <Route path="/evolution" element={<Evolution />} />
        </Routes>
    )
}