import React from 'react'
import {useState, useEffect, useReducer} from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../components/layout'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Checkbox from '@mui/material/Checkbox'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import ExpenseCard from '../components/expenseCard'
import Dialog from '@mui/material/Dialog'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import ExpenseCardDetails from '../components/expenseCardDetails'
import categoryList from '../utils/categoryList'
import TotalExpensesCard from '../components/totalExpensesCard'
import AddTransactionButton from '../components/addTransactionButton'
import transactionReducer from '../services/transactionReducer'
import {Helmet} from "react-helmet-async"

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box p={3}>
                <div>{children}</div>
            </Box>
            )}
        </div>
    )
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}
  
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}
  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})
  
export default function Expenses(){
    let navigate = useNavigate()
    const title = "home"
    const [transactions, setTransactions] = useState(null)
    const theme = useTheme()
    const [value, setValue] = useState(0)
    const [openDetails, setOpenDetails] = useState(false)
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState(null)
    const [filter, setFilter] = useState(0)
    const [allData, dispatch] = useReducer(transactionReducer, null)

    const [categories, setCategories] = useState(categoryList.map((el) =>{
        return {name:el, checked: false}
    }))

    // console.log("transactions : ", transactions)
    // console.log("alldata :", allData) 

    function handleFilterChange(event){
        setFilter(parseInt(event.target.value))
        sortData(allData, event.target.value)
    }

    function handleClickOpenDetails(transaction){
        setTransactionDetails(transaction)
        setOpenDetails(true);
    }
  
    function handleCloseDetails(){
        setOpenDetails(false);
    }

    function handleOpenModalDelete(){
        setOpenModalDelete(true)
    }

    function handleCloseModalDelete(){
        setOpenModalDelete(false)
    }
  
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    function modifyTransaction(id){
        navigate("/expenses/transaction/manage/" + id)
    }

    function performDeleteTransaction(id){
        console.log("Delete transaction : " , id)
        dispatch({type: 'delete', payload: id})
        //TODO: a vérifier s'il ne manque pas un truc
        let newArray = transactions.filter((el) => el.id !== id)
        setTransactions(newArray)
        handleCloseModalDelete()
        handleCloseDetails()
    }

    function sortData(json, filterToUse = 0){
        const today = dayjs(Date());
        const currentMonth = today.format('M');
        const jsonFiltered = json.filter((item) => today.diff(dayjs(item.spentAt)) < 28844000000);
        const arraySorted = jsonFiltered.sort(function(a,b){
            if(b.spentAt === a.spentAt)return new Date(b.updatedAt) - new Date(a.updatedAt);        
            return new Date(b.spentAt) - new Date(a.spentAt);
        })
        // 0 -> Toutes
        // 1 -> Dépenses
        // 2 -> Revenus
        // 3 -> Transactions ponctuelles
        // 4 -> Transactions régulières
        // 5 -> Par catégories
        let filteredExpenses
        switch (parseInt(filterToUse)) {
            case 0:
                filteredExpenses = arraySorted.filter((item) => {
                    // console.log("end at : ", dayjs().diff(dayjs(item.endAt)))
                    return dayjs(item.spentAt).format('M') === currentMonth || (item.isRegular === true && dayjs().diff(dayjs(item.endAt)) <= 0)
                })
                break;
            case 1:
                filteredExpenses = arraySorted.filter((item) => {
                    return dayjs(item.spentAt).format('M') === currentMonth && item.isExpense === true
                })
                break;
            case 2:
                filteredExpenses = arraySorted.filter((item) => {
                    return dayjs(item.spentAt).format('M') === currentMonth && item.isExpense === false
                })
                break;
            case 3:
                filteredExpenses = arraySorted.filter((item) => {
                    return dayjs(item.spentAt).format('M') === currentMonth && item.isRegular === false
                })
                break;
            case 4:
                filteredExpenses = arraySorted.filter((item) => {
                    return dayjs(item.spentAt).format('M') === currentMonth && item.isRegular === true
                })
                break;
            case 5:
                filteredExpenses = arraySorted.filter((item) => {
                    return dayjs(item.spentAt).format('M') === currentMonth && categories[item.category].checked === true
                })
                break;
            default:
                filteredExpenses = arraySorted.filter((item) => {
                    return dayjs(item.spentAt).format('M') === currentMonth
                })
                break;
        }
        setTransactions(filteredExpenses)
    }

    function handleFilterChangeCategory(value){
        let newArray = categories.map((el, i) => {
            if(i === value){
                el.checked = !el.checked
            }
            return el
        })
        setCategories(newArray)
        sortData(allData, 5)
    }

    useEffect(() => {
        if(allData === null){
            dispatch({type: 'get'})
        }
    }, [allData])

    useEffect(() => {
        if(transactions === null){
            if(allData !== null){
                sortData(allData, filter)
            }
        }
    }, [transactions, allData])

    return (
        <Layout title={title} >
            <Helmet>
                <title>Expenses - Optimisez vos dépenses</title>
                <link rel="canonical" href={"https://expenses.johan-clement.fr/expenses"} /> 
                <meta name="robots" content="index,follow" />
                <meta name="description" content={`Expenses vous aide à suivre vos dépenses. Facile à utiliser et optimisé pour mobile, cette application peut vous suivre partout.`} />
            </Helmet>
            <Container>
                <main className="mx-auto md:w-1/2">
                    {transactions === null?
                        <>
                            <p className="text-center font-bold mt-60">Chargement...</p>
                        </>
                    :
                        <>
                            <h1 className="text-center font-bold mt-5 mb-2 text-2xl">
                                Vos transactions du mois
                            </h1>
                            <TotalExpensesCard data={transactions} />
                            {allData.length > 0 ?(
                                <div className="mb-12">
                                    <AppBar position="static" color="default">
                                        <Tabs
                                        value={value}
                                        onChange={handleChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="fullWidth"
                                        aria-label="full width tabs example"
                                        >
                                            <Tab label="Transactions" {...a11yProps(0)} />
                                            <Tab label="Filtres" {...a11yProps(1)} />
                                        </Tabs>
                                    </AppBar>
                                    <TabPanel value={value} index={0} className="mb-28" dir={theme.direction}>
                                        {transactions.map((transaction, i) => (
                                            <div key={i} >
                                                <button onClick={() => handleClickOpenDetails(transaction)} className="w-full">
                                                    <ExpenseCard data={transaction} />
                                                </button>
                                            </div>
                                        ))}
                                    </TabPanel>
                                    <TabPanel value={value} index={1} dir={theme.direction}>
                                        <div className="mt-4 px-5">
                                            <FormControl component="fieldset" >
                                                <FormLabel component="legend">Filtrer selon vos critères</FormLabel>
                                                <RadioGroup aria-label="filter" name="filter" value={value} onChange={handleFilterChange}>
                                                    <FormControlLabel value="0" checked={filter===0?true:false} control={<Radio />} label="Toutes" />
                                                    <FormControlLabel value="1" checked={filter===1?true:false} control={<Radio />} label="Dépenses" />
                                                    <FormControlLabel value="2" checked={filter===2?true:false} control={<Radio />} label="Revenus" />
                                                    <FormControlLabel value="3" checked={filter===3?true:false} control={<Radio />} label="Transactions ponctuelles" />
                                                    <FormControlLabel value="4" checked={filter===4?true:false} control={<Radio />} label="Transactions régulières" />
                                                    <FormControlLabel value="5" checked={filter===5?true:false} control={<Radio />} label="Par catégorie" />
                                                </RadioGroup>
                                            </FormControl>  
                                            {filter === 5?(
                                                <div className="ml-10 mb-10">
                                                    {categoryList.map((element, i) => (
                                                        <div key={i}>
                                                        <FormControlLabel 
                                                            control={<Checkbox checked={categories[i].checked} onChange={() => handleFilterChangeCategory(i)} name={"category" + i} />}
                                                            label={element}
                                                        />
                                                        </div>    
                                                    ))}
                                                    
                                                </div>     
                                            ):null} 
                                        </div>
                                    </TabPanel>
                                </div>
                            ):(
                                <>
                                    <p className="text-center">Aucune transaction enregistrée sur ce compte</p>
                                    <p className="text-center">Cliquez sur le bouton + pour ajouter vos transactions</p>
                                </>
                            )}
                        </>
                    }
                </main>
                <Dialog fullScreen open={openDetails} onClose={handleCloseDetails} TransitionComponent={Transition}>
                    <AppBar>
                        <Toolbar className="flex justify-between">
                            <IconButton edge="start" color="inherit" onClick={handleCloseDetails} aria-label="close">
                                <CloseIcon /><span className="text-base ml-1">Fermer</span>
                            </IconButton>
                            {transactionDetails?(
                                <button onClick={()=> modifyTransaction(transactionDetails.id)}>
                                    <span className="text-base ml-1">Modifier</span>
                                </button>    
                            ):null}
                        </Toolbar>
                    </AppBar>
                    <div className="h-full">
                        <div className="mt-20 text-center">
                            <h1 className="font-bold uppercase text-xl">Détails</h1>
                        </div>
                        <div className="px-4 mt-4 mx-auto md:w-1/2">
                            <ExpenseCardDetails data={transactionDetails} />
                        </div>
                        <div className="absolute bottom-4 w-full text-center">
                            <Button onClick={handleOpenModalDelete}>
                                <span className="text-red-600">Supprimer cette transaction</span>
                            </Button>    
                        </div>
                    </div>
                    <Dialog
                        open={openModalDelete}
                        onClose={handleCloseModalDelete}
                    >
                        <DialogTitle>Confirmer la suppression</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Cette action est définitive. Veuillez confirmer la suppression de cette transaction.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleCloseModalDelete} color="primary" autoFocus>
                            Annuler
                        </Button>
                        <Button onClick={() => performDeleteTransaction(transactionDetails.id)} color="secondary">
                            Confirmer
                        </Button>
                        </DialogActions>
                    </Dialog>
                </Dialog>
            </Container>
            <AddTransactionButton />
        </Layout>
    )
}