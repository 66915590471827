import React, {useState, useEffect, useReducer} from 'react'
import Layout from '../components/layout'
import {Formik, Form} from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import transactionReducer from '../services/transactionReducer'
import * as Yup from 'yup'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Container from '@mui/material/Container'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Grid from '@mui/material/Grid'
import ButtonGroup from '@mui/material/ButtonGroup'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import categoryList from '../utils/categoryList'
import {Helmet} from "react-helmet-async"

const SignupSchema = Yup.object().shape({
    amount: Yup.number()
      .min(0, 'Nom d\'utilisateur incomplet')
      .max(1000000000, 'Somme trop importante. Anormal pour une transaction')
      .required('Champ obligatoire'),
    title: Yup.string()
      .min(2, 'Titre incomplet')
      .max(255, 'Faire une description plus courte')
      .required('Champ obligatoire'),
})

const defaultTransaction = {
    id: null,
    amount: '',
    title: '',
    spentAt: new Date(),
    endAt: null,
    isExpense: true,
    isRegular: false,
    spendMoment: null,
    category: 0
}

export default function TransactionManager({transactionProp}){
    const id = useParams().id;
    let navigate = useNavigate();
    const [openDialogCategory, setOpenDialogCategory] = useState(false);
    const [transaction, setTransaction] = useState(transactionProp?transactionProp:defaultTransaction)
    const [state, dispatch] = useReducer(transactionReducer, null);

    const handleDateChange = (date) => {
        setTransaction(prevValues => ({...prevValues, spentAt: date}))
    }

    const handleEndDateChange = (date) => {
        setTransaction(prevValues => ({...prevValues, endAt: date}))
    }

    function handleChangeExpense(value){
        setTransaction(prevValues => ({...prevValues, isExpense: value}))
    }

    function handleChangeExpenseFrequency(value){
        if(transaction.spendMoment !== null)setTransaction({...transaction, spendMoment: null})
        setTransaction(prevValues => ({...prevValues, isRegular: value}))
    }

    function handleChangeExpenseMomentSchedule(value){
        setTransaction(prevValues => ({...prevValues, spendMoment: parseInt(value.target.value)}))
    }

    function changeCategory(){
        setOpenDialogCategory(true);
    }

    function handleCloseModal(){
        setOpenDialogCategory(false)
    }

    function handleChangeCategory(val){
        // console.log('value : ' , val.target.value, categoryList[val.target.value])
        setTransaction(prevValues => ({...prevValues, category: parseInt(val.target.value)}))
        handleCloseModal()
    }

    useEffect(() => {
        if(state === null){
            dispatch({type: 'getOne', payload: id})
        }
    }, [state])

    useEffect(() => {
        if(id !== undefined){
            setTransaction(state)
        }
    }, [state, id])

    // console.log("state : ", state, transaction)

    return (
        <Layout>
            <Helmet>
                <title>Expenses - Consulter votre historique</title>
                <link rel="canonical" href={"https://expenses.johan-clement.fr/expenses/transaction/manage"} /> 
                <meta name="robots" content="index,follow" />
                <meta name="description" content={`Expenses vous aide à suivre vos dépenses. Facile à utiliser et optimisé pour mobile, cette application peut vous suivre partout.`} />
            </Helmet>
            {state === null || transaction === null?
                <>
                    Loading...
                </>
            :
                <Container>
                    <div className="text-center font-bold my-5 text-2xl">
                        <h1>Transaction</h1>
                    </div>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{ amount: transaction.amount, title: transaction.title }}
                        validationSchema={SignupSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            values = {...transaction, ...values}
                            // console.log("Values to send : ", values)
                            if(transaction.id === null){
                                // console.log(" add values : ", values)
                                dispatch({type: 'add', payload : values})
                                return navigate("/expenses")
                            }else{
                                dispatch({type: 'modify', payload: values})
                                return navigate("/expenses")
                            }
                        }}
                    >
                    {formik => (
                        <Form className="text-center mx-auto md:w-1/3" onSubmit={formik.handleSubmit} >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                                        <Button className={transaction.isRegular===false?'selectedTypePonctual':''} onClick={() => handleChangeExpenseFrequency(false)}>Ponctuelle</Button>
                                        <Button className={transaction.isRegular===true?'selectedTypeRegular':''} onClick={() => handleChangeExpenseFrequency(true)}>Régulière</Button>
                                    </ButtonGroup>    
                                </Grid>
                                {transaction.isRegular===true?(
                                    <Grid item xs={12}>
                                        <RadioGroup name="spendMoment" onChange={handleChangeExpenseMomentSchedule}>
                                            <FormControlLabel value="1" checked={transaction.spendMoment===1?true:false} control={<Radio />} label="Début du mois" />
                                            <FormControlLabel value="2" checked={transaction.spendMoment===2?true:false} control={<Radio />} label="Milieu du mois" />
                                            <FormControlLabel value="3" checked={transaction.spendMoment===3?true:false} control={<Radio />} label="Fin du mois" />
                                        </RadioGroup>
                                    </Grid>    
                                ):null}
                                <Grid item xs={12} >
                                    <TextField fullWidth={true} type="number"
                                        name="amount" label="Montant" 
                                        variant="outlined"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                        }}
                                        helperText={formik.errors.amount && formik.touched.amount ? (formik.errors.amount) : null} 
                                        error={formik.errors.amount && formik.touched.amount ? true : null} 
                                        onChange={formik.handleChange} 
                                        value={formik.values.amount}/> 
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                                        <Button className={transaction.isExpense===true?'selectedTypeExpense':''} onClick={() => handleChangeExpense(true)}>Dépense</Button>
                                        <Button className={transaction.isExpense===false?'selectedTypeRevenu':''} onClick={() => handleChangeExpense(false)}>Revenu</Button>
                                    </ButtonGroup>    
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth={true} name="title" type="text"
                                        label="Description" variant="outlined" 
                                        size="small"
                                        helperText={formik.errors.title && formik.touched.title? (formik.errors.title) : "Entre 2 et 255 caractères"} 
                                        error={formik.errors.title && formik.touched.title? true : null} 
                                        onChange={formik.handleChange} value={formik.values.title}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <p className="text-left mb-2">Catégorie : {categoryList[transaction.category]}</p>
                                    <Button color="primary" variant="outlined" onClick={changeCategory}>Modifier</Button>
                                </Grid>
                                <div className="w-full px-2 mt-5">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label={transaction.isRegular?"Date de début":"Date"}
                                            value={transaction.spentAt}
                                            onChange={handleDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                {transaction.isRegular === true &&
                                    <div className="w-full px-2 mt-5">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label={"Date de fin"}
                                                value={transaction.endAt}
                                                onChange={handleEndDateChange}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                }
                                
                                <hr className="mt-2 mx-auto"/>
                                <div className="text-center mx-auto mt-4 mb-24">
                                    <Button size="large" color="primary" variant="outlined" type="submit">Valider</Button> 
                                </div>
                            </Grid>
                        </Form>
                        )}
                    </Formik>
                    <Dialog disablebackdropclick="true" disableEscapeKeyDown open={openDialogCategory} onClose={handleCloseModal}>
                        <DialogTitle>Sélectionner une catégorie</DialogTitle>
                        <DialogContent>
                            <FormControl>
                                <RadioGroup name="category" onChange={handleChangeCategory}>
                                    {categoryList.map((element, i) => (
                                        <FormControlLabel value={i} key={i} checked={i === transaction.category?true:false} control={<Radio />} label={element} />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseModal} color="primary">
                                Annuler
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            }
        </Layout>
    )
}