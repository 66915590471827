import React from 'react'
import { useReducer, useEffect, useState } from 'react'
import Layout from '../components/layout'
import dayjs from 'dayjs'
import transactionReducer from '../services/transactionReducer'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import {Helmet} from "react-helmet-async"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const table = () => {
    let out = []
    const data = dayjs(Date()).format('M')
    for (let i = data; i > data - 12; i--) {
        if(i <= 0){
            out.push({index : i+12, currentYear: false})
        }else{
            out.push({index: parseInt(i), currentYear: true})
        }
    }
    // console.table(out)
    return out
}

export default function Evolution(){
    const title = "evolution"
    const [allData, dispatch] = useReducer(transactionReducer, null)
    const [data, setData] = useState(null)
    const [orientation, setOrientation] = useState(1)
    const [duration, setDuration] = useState(6);

    const handleChangeDuration = (event) => {
        setDuration(parseInt(event.target.value))
        sortData(parseInt(event.target.value))
    }

    // console.log('allData : ', allData, data)

    function createArray(start, withDuration){
        let array = []
        for(let i=0; i < withDuration; i++){
            let val = start - i
            if(val <= 0)val += 12
            array.push(val)
        }
        // console.table(array)
        return array
    }

    function is_included(start, end, month){
        let isCurrentYear = table().find((el)=> el.index === month)
        const year = isCurrentYear.currentYear?dayjs().year():dayjs().year() -1
        month = month - 1
        const startMonth = dayjs().year(year).month(month).date(1) // C   start // A
        const endMonth = dayjs().year(year).month(month).date(31) // D    end // B
        // console.log("end : ", dayjs(end), startMonth, dayjs(end).diff(startMonth))
        // console.log("month :", month)
        // console.log("1ere condition : ", dayjs(start).diff(startMonth) < 0 , dayjs(end).diff(startMonth) > 0, dayjs(start).diff(startMonth) < 0 && dayjs(end).diff(startMonth) > 0)
        // console.log("2eme condition : ", dayjs(start).diff(startMonth) > 0 , dayjs(start).diff(endMonth) < 0, dayjs(start).diff(startMonth) > 0 && dayjs(start).diff(endMonth) < 0)
        if( (dayjs(start).diff(startMonth) < 0 && dayjs(end).diff(startMonth) > 0)
            || (dayjs(start).diff(startMonth) > 0 && dayjs(start).diff(endMonth) < 0)
        )return true
        return false
    }

    function sortData(withDuration){
        let periodStart = parseInt(dayjs(Date()).format('M'))
        const months = ['','Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];

        let x = createArray(periodStart, withDuration)
        let y = []
        x.forEach(period => {
            let filteredExpenses = allData.filter((item) => {
                return parseInt(dayjs(item.spentAt).format('M')) === period || (item.isRegular === true && is_included(item.spentAt, item.endAt, period))
            })
            const totalExpensesReduced = filteredExpenses.reduce((currentTotal, item) => {
                if(item.isExpense === false) return item.amount + currentTotal
                return currentTotal - item.amount
            }, 0).toFixed(2)
            y.push(parseInt(totalExpensesReduced))
        })
        x = x.reverse().map((el)=>{
            return months[el]
        })
        // console.log("total : ", x, y)
        setData({x, y: y.reverse()})
    }

    useEffect(() => {
        // console.log("effect allData : ", allData)
        if(allData === null){
            dispatch({type: 'get'})
        }
    }, [allData])

    useEffect(() => {
        if(data === null){
            if(allData !== null){
                sortData(6)
            }
        }
    }, [data, allData])
   
    function handleResize() {
        // console.log('resized to: ', window.matchMedia('(orientation:landscape)').matches)
        const out = window.matchMedia('(orientation:landscape)').matches === true?2:1
        setOrientation(out)
    }

    window.addEventListener('resize', handleResize)

    const options = {
        responsive: true,
        aspectRatio: orientation,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Evolution',
            },
        },
    }

    const datas = {
        labels: data === null?[]:data.x,
        datasets: [
            {
                label: 'Total dépenses',
                data: data === null?[]:data.y,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    }

    return(
        <Layout title={title}>
            <Helmet>
                <title>Expenses - Consulter votre historique</title>
                <link rel="canonical" href={"https://expenses.johan-clement.fr/evolution"} /> 
                <meta name="robots" content="index,follow" />
                <meta name="description" content={`Expenses vous aide à suivre vos dépenses. Facile à utiliser et optimisé pour mobile, cette application peut vous suivre partout.`} />
            </Helmet>
            {allData === null ?
                <p>
                    Loading...
                </p>
            :
                <div className="chart-dimensions">
                    <Line
                        options={options}
                        data={datas}
                    />

                    <RadioGroup
                        aria-label="duration"
                        name="duration"
                        value={duration}
                        onChange={handleChangeDuration}
                    >
                        <div className="center-flex">
                            <FormControlLabel value="6" control={<Radio />} label="6 mois" />
                            <FormControlLabel value="12" control={<Radio />} label="12 mois" />
                        </div>
                    </RadioGroup>

                </div>
            }
            
        </Layout>
    )
}