import React from 'react'
import dayjs from 'dayjs'
import categoryList from '../utils/categoryList'

export default function ExpenseCard({data}){

    function formatCategory(value){
        return categoryList[value]
    }

    function formatDate(date){
        return dayjs(date).format('DD MMM')
    }

    function formatMoment(value){
        const moments = ["", "Début", "Milieu", "Fin"]
        return moments[value]
    }

    return(
        <div className="bg-white rounded mb-1 px-2 py-1 shadow ">
            <div className="flex justify-between">
                <span>{data.title}</span>
                {data.isExpense?(
                    <span className="font-bold text-gray-900">-{data.amount} €</span>
                ):(
                    <span className="font-bold text-green-800">{data.amount} €</span>
                )}
            </div>
            <div className="flex justify-between">
                <span className="text-xs">{formatCategory(data.category)}</span>
                {data.isRegular?(
                    <span className="text-xs">{formatMoment(data.spendMoment)} de mois</span>
                ):(
                    <span className="text-xs">{formatDate(data.spentAt)}</span>
                )}
                
            </div>

            
        </div>
    )
}
