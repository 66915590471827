import React from 'react'

export default function TotalExpensesCard({data}){
    if(data === null)return (<></>)
    const totalExpenses = data.reduce((currentTotal, item) => {
        // console.log("cal:", currentTotal, item.isExpense, item.amount)
        if(item.isExpense === true) return item.amount + currentTotal
        return currentTotal
    }, 0).toFixed(2)

    const totalRevenus = data.reduce((currentTotal, item) => {
        if(item.isExpense === false) return item.amount + currentTotal
        return currentTotal
    }, 0).toFixed(2)

    const totalExpensesDeducted = data.reduce((currentTotal, item) => {
        if(item.isExpense === false) return item.amount + currentTotal
        return currentTotal - item.amount
    }, 0).toFixed(2)

    // console.log("data : ", data, totalExpenses, totalRevenus, totalExpensesDeducted)

    return(
        <>
            <div className="text-center">
                <div className="flex justify-around">
                    <span className="font-bold text-gray-600">Total des dépenses</span>
                    <span className="font-bold text-gray-600">Total des revenus</span>
                </div>
                <div className="flex justify-around">
                    <span className="text-base font-bold">{totalExpenses} €</span>
                    <span className="text-base font-bold">{totalRevenus} €</span>
                </div>
            </div>
            <div className="text-center mb-4">
                    <div className="text-base font-bold">Total balance</div>
                    <div className="text-base font-bold">{totalExpensesDeducted} €</div>
            </div>
        </>
    )
}
