import React from 'react'
import { useNavigate } from "react-router-dom"
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import RestoreIcon from '@mui/icons-material/Restore';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'

export default function Layout(props) {

    let navigate = useNavigate()
    const title = props.title

    return (
        <>
            <div>
                {props.children}
                <div className="fixed bottom-0 w-full">
                    <BottomNavigation
                        onChange={(event, value) => {
                            navigate(value)
                        }}
                        showLabels
                        >
                        <BottomNavigationAction value="/expenses" label="Transactions" className={title==='home'?'selectedBtn':''} icon={<SwapHorizIcon />} />
                        <BottomNavigationAction value="/history" label="Historique" className={title==='history'?'selectedBtn':''} icon={<RestoreIcon />} />
                        <BottomNavigationAction value="/evolution" label="Evolution" className={title==='evolution'?'selectedBtn':''} icon={<BarChartOutlinedIcon />} />
                    </BottomNavigation>    
                </div>
            </div>
        </>
    )
}
