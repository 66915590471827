
export const categoryList = [
    "Non précisé",
    "Alimentation",
    "Essence",
    "Divertissement",
    "Restaurant & Sortie",
    "Shopping",
    "Soin de santé",
    "Transport & Véhicule",
    "Personnel",
    "Réparation",
    "Cadeaux & Dons",
    "Voyage",
    "Loyer",
    "Abonnement eau",
    "Abonnement électricité",
    "Abonnement gaz",
    "Abonnement internet",
    "Abonnement assurance",
    "Abonnement téléphone",
    "Abonnement divertissement",
    "Impôts",
    "Enfants et scolaire",
    "Frais bancaire",
    "Remboursement prêt",
    "Remboursement",
    "Salaire",
    "Revenus secondaire",
    "Autre"
]
export default categoryList