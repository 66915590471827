

export default function MonthPeriods({data}){

    const MonthPeriods = 11     // App can display data up to 11 months (it's enough...)
    const currentYear = new Date().getFullYear()
    function displayData(){
        let table = []
        for (let i = data; i > data - MonthPeriods; i--) {
            if(i <= 0){
                table.push({index : i+12, currentYear: false})
            }else{
                table.push({index: i, currentYear: true})
            }
        }
        return table
    }
    
    let monthToDisplay = displayData()
    // console.log("Month to display : ", monthToDisplay)
    const tablePeriodMonth = [
        null,
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ]

    return (
        <>
            {monthToDisplay.map((element,i) => {
                return (
                    <option key={i} value={element.index}>{tablePeriodMonth[element.index]} {element.currentYear?currentYear:currentYear-1}</option>
                )
            })}
        </>
    )
}