import CustomRouter from "./components/customRouter"
import { BrowserRouter } from "react-router-dom"
import { HelmetProvider } from 'react-helmet-async'

function App() {
    return (
        <HelmetProvider>
            <BrowserRouter>
                <CustomRouter />
            </BrowserRouter>
        </HelmetProvider>
    )
}

export default App
