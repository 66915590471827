import React from 'react'
import dayjs from 'dayjs'
import categoryList from '../utils/categoryList'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

export default function ExpenseCardDetails({data}){

    function formatCategory(value){
        return categoryList[value]
    }

    function formatDate(date){
        return dayjs(date).format('DD MMM YYYY')
    }

    function formatDate2(date){
        return dayjs(date).format('DD MMM YYYY à hh:mm')
    }

    function formatMoment(value){
        const moments = ["", "Début de mois", "Milieu de mois", "Fin de mois"]
        return moments[value]
    }
    return(
        <Card>
            <CardContent>
                {data.isExpense?
                    <div className="text-center border w-40 p-2 mx-auto rounded selectedTypeExpense">
                        <span className="text-black font-bold">Dépense</span>
                    </div>
                :
                    <div className="text-center border w-40 p-2 mx-auto rounded selectedTypeRevenu">
                        <span className="text-black font-bold">Revenu</span>
                    </div>
                }
                <div className="text-2xl text-center font-bold my-3">
                    {data.isExpense?'-':''}{data.amount} €
                </div>
                <div className="text-xl mb-2">
                    {data.title}
                </div>
                <Typography color="textSecondary">
                    Catégorie :<span className="text-black"> {formatCategory(data.category)}</span> 
                </Typography>
                {data.is_regular?(
                    <Typography color="textSecondary">
                        Prélèvement auto : <span className="text-black">{formatMoment(data.spendMoment)}</span>
                    </Typography>
                ):(
                    <Typography color="textSecondary">
                        Datant du : <span className="text-black">{formatDate(data.spentAt)}</span>
                    </Typography>
                )}
                <Typography color="textSecondary">
                    Type de transaction : <span className="text-black">{data.isRegular?'Régulière':'Ponctuelle'}</span>
                </Typography>
                <hr className="w-40 mx-auto my-2"/> 
                <Typography variant="body2">
                    Créé le : {formatDate2(data.createdAt)}
                </Typography>
                <Typography variant="body2">
                    Mis à jour : {formatDate2(data.updatedAt)}
                </Typography>
            </CardContent>
        </Card>
    )
}
