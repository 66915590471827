import React from 'react'
import {useEffect, useReducer} from 'react'
import { useNavigate } from 'react-router-dom'
import {Link} from 'react-router-dom'
import main_page from '../assets/img/main_page_small.webp'
import transaction_page from '../assets/img/transaction_page_small.webp'
import details_page from '../assets/img/details_page_small.webp'
import history_page from '../assets/img/history_page_small.webp'
import {Helmet} from "react-helmet-async"
import transactionReducer from '../services/transactionReducer'

export default function Home(){
    let navigate = useNavigate()
    const [allData, dispatch] = useReducer(transactionReducer, null)

    useEffect(() => {
        if(allData === null){
            dispatch({type: 'get'})
            console.log("all data useEffect : ", allData)

        }
    }, [allData])
    
    useEffect(() => {
        if(allData && allData.length > 0){
            navigate("/expenses")
        }
    }, [allData])
    
    return(
        <>
            <Helmet>
                <title>Expenses - Optimisez vos dépenses</title>
                <link rel="canonical" href={"https://expenses.johan-clement.fr"} /> 
                <meta name="robots" content="index,follow" />
                <meta name="description" content={`Expenses vous aide à suivre vos dépenses. Facile à utiliser et optimisé pour mobile, cette application peut vous suivre partout.`} />
            </Helmet>
            <div className="p-5">
                <div className="text-right mr-2 mt-5">
                    <Link to="/expenses" className="border border-black border-solid rounded p-2">On commence</Link>
                </div>
                <h1 className="text-center text-2xl font-bold my-5">Expenses</h1>
                <p className="text-center">L'outil le plus simple pour suivre vos dépenses</p>
                <hr className="text-center mx-auto my-5" />
                <p>Suivez vos dépenses chaque jour en ajoutant au fur et à mesure vos transactions quotidiennes.</p>
                <p>C'est un peu comme vous le feriez avec un compte Tricount, sauf que là c'est pour les dépenses de tous les jours.</p>
                <div className="text-center mx-auto p-4 bg-white rounded my-4">
                    <img
                        src={main_page}
                        alt="main page expenses"
                        className="mx-auto md:w-1/3"
                    />
                </div>
                <p>Ajoutez autant de transactions que vous voulez sans vous ruiner.</p>
                <div className="text-center mx-auto p-4 bg-white rounded my-4">
                    <img
                        src={transaction_page}
                        alt="transaction page expenses"
                        className="mx-auto md:w-1/3"
                    />
                </div>
                <p>Organisez vos transactions en fonction de vos dépenses, de vos revenus, de vos abonnements réguliers.</p>
                <div className="text-center mx-auto p-4 bg-white rounded my-4">
                    <img
                        src={details_page}
                        alt="transaction page expenses"
                        className="mx-auto md:w-1/3"
                    />
                </div>
                <p>Consultez votre historique sur une année glissante. Vous pourrez ainsi suivre l'évolution de vos dépenses sur plusieurs mois.</p>
                <div className="text-center mx-auto p-4 bg-white rounded my-4">
                    <img
                        src={history_page}
                        alt="transaction page expenses"
                        className="mx-auto md:w-1/3"
                    />
                </div>
                <p>Cette application pour mobile est entièrement gratuite. Ils suffit juste de créer vos identifiants et de vous connecter.</p>
                <p className="mb-10">Vos données personnelles ne sont pas utilisées car elles ne sont tout simplement pas demandées pour utiliser cette application.</p>
            </div>
        </>
    )
}