import React from 'react'
import { useNavigate } from 'react-router-dom'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'

export default function AddTransactionButton(){
    let navigate = useNavigate();

    function addTransaction(){
        navigate("/expenses/transaction/manage")
    }

    return(
        <div className="fixed bottom-16 right-5">
            <Fab color="primary" aria-label="add" onClick={addTransaction}>
                <AddIcon />
            </Fab>    
        </div>
    )
}