
function guid() {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + s4() + s4();
}

export default function transactionReducer(state, action) {
    let transactions = JSON.parse(localStorage.getItem('transactions'))
    switch (action.type) {
        case 'get':
            {
                // console.log("print : ", transactions)
                if(transactions === null) {
                    localStorage.setItem('transactions', JSON.stringify([]))
                    console.log("creation storage ")
                    return []
                }
                return transactions
            }
        case 'getOne':
            {
                return transactions.find((el)=> el.id === action.payload)
            }
        case 'add':
            {
                let newItem = action.payload
                newItem.id = guid()
                newItem.updatedAt = new Date()
                newItem.createdAt = new Date()
                localStorage.setItem('transactions', JSON.stringify([...transactions, newItem]))
                break
            }
        case 'delete':
            {
                localStorage.setItem('transactions', JSON.stringify(transactions.filter((el)=> el.id !== action.payload)))
                return state.filter((el)=> el.id !== action.payload)
            }
        case 'modify':
            action.payload.updatedAt = new Date()
            localStorage.setItem('transactions', JSON.stringify(transactions.map((el)=> {
                if(el.id === action.payload.id){
                    return action.payload
                }
                return el
            })))
            break
        default:
            return state
    }
    return state
}